@use '@fixed-size' as *;
@use '@queries' as *;

.illustration-card-category {
  @include singleFixedSize(118px);

  @include min-428-break {
    @include singleFixedSize(157px);
  }
}
